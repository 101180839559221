import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Peers from "../../assets/peers.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const stylesComponent = {
  sectionWrapper: {
    backgroundColor: "white",
    pt: 8,
    pb: 8,
  },
  sectionHeader: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "28px",
  },
  learnMoreBtn: {
    color: "#E60005",
    fontSize: "18px",
    fontWeight: "700",
    cursor: "pointer",
  },
  icon: {
    color: "#E60005",
    fontSize: "12px",
  },
};

const JoinPeersSection = (): React.ReactElement => {
  const { t } = useTranslation("base");

  return (
    <Grid mt={2} mb={6} sx={stylesComponent.sectionWrapper} width={"100%"}>
      <Box>
        <Grid container alignContent={"center"} justifyContent={"center"}>
          <Grid
            item
            maxWidth={{ xs: "350px", md: "504px" }}
            p={{ xs: 1, md: 1, lg: 0 }}
          >
            <img
              src={Peers}
              alt="peers"
              style={{ borderRadius: "20px", width: "100%", maxWidth: "100%" }}
            />
          </Grid>

          <Grid
            item
            mr={{ xs: 4 }}
            ml={{ xs: 4 }}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              maxWidth={{ xs: "335px", md: "404px" }}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography mt={2} sx={stylesComponent.sectionHeader}>
                {t("JoinPeers.0")}
              </Typography>
              <Typography mt={2} maxWidth={{ xs: "350px", md: "320px" }}>
                {t("JoinPeers.1")}
              </Typography>
              <Typography mt={3} sx={stylesComponent.learnMoreBtn}>
                {t("LearnMoreBtn")}
                <ArrowForwardIosIcon sx={stylesComponent.icon} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default JoinPeersSection;
