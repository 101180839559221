import React, { createContext, useContext, useEffect, useState } from "react";
import schoolService from "../services/schoolService";
import { School } from "../models/schoolTypes";
import { schoolIntialValues } from "../models/initialValues";
import { Expert } from "../models/expert";

type SchoolContext = {
  schools: School[];
  experts: Expert[];
  classes: number[];
  selectedSchool?: School;
  selectedClass: number | null;
  zip: string;
  shoolSelelectionOpenGDPRModal: boolean;
  selectedExpertBookingLink: string;
  chatAdviceOpenGDPRModal: boolean;
  chatAdviceGDPRModalWhatsAppSelected: boolean;
  setZip: (value: string) => void;
  setSelectedSchool: (value: any) => void;
  setClass: (value: any) => void;
  setOpenShoolSelelectionGDPRModal: (value: any) => void;
  setSelectedExpertBookingLink: (value: any) => void;
  setOpenChatAdviceGDPRModal: (value: any) => void;
  setSelectChatAdviceGDRPModalWhatsApp: (value: any) => void;
  isSchoolsLoaded: boolean;
};

const SchoolsData = createContext<SchoolContext>({
  schools: [],
  experts: [],
  classes: [],
  selectedSchool: schoolIntialValues,
  selectedClass: null,
  zip: "",
  shoolSelelectionOpenGDPRModal: false,
  selectedExpertBookingLink: "",
  chatAdviceGDPRModalWhatsAppSelected: false,
  chatAdviceOpenGDPRModal: false,
  isSchoolsLoaded: false,
  setZip() {},
  setSelectedSchool() {},
  setClass() {},
  setOpenShoolSelelectionGDPRModal() {},
  setSelectedExpertBookingLink() {},
  setOpenChatAdviceGDPRModal() {},
  setSelectChatAdviceGDRPModalWhatsApp() {},
});

type Props = {
  children: React.ReactNode;
};

const SchoolsProvider = ({ children }: Props): React.ReactElement => {
  const [schools, setSchools] = useState<School[]>([]);
  const [isSchoolsLoaded, setIsSchoolsLoaded] = useState<boolean>(false);
  const [experts, setExperts] = useState<Expert[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<School>();
  const [selectedClass, setClass] = useState<number | null>(null);
  const [zip, setZip] = useState<string>("");
  const [classes, setClasses] = useState<number[]>([]);
  const [shoolSelelectionOpenGDPRModal, setOpenShoolSelelectionGDPRModal] =
    useState<boolean>(false);
  const [selectedExpertBookingLink, setSelectedExpertBookingLink] =
    useState<string>("");
  const [chatAdviceOpenGDPRModal, setOpenChatAdviceGDPRModal] =
    useState<boolean>(false);
  const [
    chatAdviceGDPRModalWhatsAppSelected,
    setSelectChatAdviceGDRPModalWhatsApp,
  ] = useState<boolean>(false);

  const reset = () => {
    setExperts([]);
    setClass(null);
    setClasses([]);
    setSelectedSchool(schoolIntialValues);
  };

  const getSelectedSchools = () => {
    if (zip.length === 5) {
      setIsSchoolsLoaded(false);
      schoolService
        .getSchools(zip)
        .then((res: any) => {
          reset();
          setSchools(res.data.data.schools);
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.log(err);
          setIsSchoolsLoaded(true);
        })
        .finally(() => {
          setIsSchoolsLoaded(true);
        });
    }
  };

  useEffect(() => {
    if (
      selectedSchool &&
      selectedSchool !== schoolIntialValues &&
      selectedClass !== null
    ) {
      setExperts(
        selectedSchool.experts.filter((e: Expert) => {
          return e.classes.includes(selectedClass);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass]);

  useEffect(() => {
    if (selectedSchool && selectedSchool.zip !== "") {
      const classes: number[] = [];
      selectedSchool.experts.forEach((e: Expert) => {
        e.classes.forEach((c: number) => {
          if (!classes.includes(c)) {
            classes.push(c);
          }
        });
      });
      setClasses(classes.sort((n1, n2) => n1 - n2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  useEffect(() => {
    getSelectedSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip]);

  const providerValue: SchoolContext = {
    schools,
    classes,
    experts,
    selectedSchool,
    selectedClass,
    zip,
    setZip,
    setSelectedSchool,
    setClass,
    shoolSelelectionOpenGDPRModal,
    setOpenShoolSelelectionGDPRModal,
    selectedExpertBookingLink,
    setSelectedExpertBookingLink,
    chatAdviceOpenGDPRModal,
    setOpenChatAdviceGDPRModal,
    chatAdviceGDPRModalWhatsAppSelected,
    setSelectChatAdviceGDRPModalWhatsApp,
    isSchoolsLoaded,
  };

  return (
    <SchoolsData.Provider value={providerValue}>
      {children}
    </SchoolsData.Provider>
  );
};

const useSchools = (): SchoolContext => useContext<SchoolContext>(SchoolsData);

export default SchoolsProvider;
export { SchoolsData, useSchools };
