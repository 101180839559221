import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import peersListService from "../../services/peersListService";
import { PeerSliderSection } from "./SliderSection/PeerSliderSection";
import { Peer } from "../../models/peer";

const stylesComponent = {
  sectionWrapper: {
    backgroundColor: "#fff",
  },
  sectionHeader: {
    fontSize: "28px",
    fontWeight: "700",
  },
  icon: {
    color: "#2276D0 ",
    cursor: "pointer",
  },
  iconDisabled: {
    color: "#B4B4B4 ",
    cursor: "pointer",
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  },
  peerName: {
    fontSize: "20px",
    fontWeight: "700",
  },
  peerInfo: {
    fontSize: "18px",
    fontWeight: "500",
    fontStyle: "italic",
    padding: "0 10px",
  },
  border: {
    textAlign: "center",
    width: "250px",
    borderBottom: "1px solid #D9D9D9",
    margin: "0 auto",
    marginTop: "50px",
  },
};

const PeersInfoSection = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const [peersList, setPersList] = useState<Peer[]>();

  const fetchOpeningHours = () => {
    peersListService
      .getPeersListService()
      .then((res: any) => {
        setPersList(res.data.data.peers);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchOpeningHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if we want to show avatars from BE then change this functionality

  return (
    <Grid
      item
      xs={12}
      mt={{ xs: 3 }}
      m={{ md: "120px 180px" }}
      display="none" // Remove if the peer quotes are available
      sx={stylesComponent.sectionWrapper}
    >
      <Box mt={2} sx={stylesComponent.sectionHeader}>
        {t("PeersHeader")}
      </Box>
      <PeerSliderSection peers={peersList} />
    </Grid>
  );
};

export default PeersInfoSection;
