import React from "react";
import { Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const stylesComponent = {
  contactBtn: {
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "700",
    borderRadius: "64px",
    float: "left",
    margin: "20px",
  },
};

const ContactUs = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const isMobile = useMediaQuery("(max-width:700px)", { noSsr: true });

  return (
    <Button
      sx={stylesComponent.contactBtn}
      style={{ width: `${isMobile ? "calc(100% - 40px)" : "233px"}` }}
      href={"/kontakt"}
    >
      {t("ParticipateExpertsContactUs.Button")}
    </Button>
  );
};

export default ContactUs;
