import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraIcon from "../../assets/cameraIcon.svg";
import { useSchools } from "../../context/SchoolContext";
import { Expert } from "../../models/expert";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const stylesComponent = {
  sectionWrapper: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  sectionHeaderWrapper: {
    width: { xs: "375px", md: "560px" },
    mt: 4,
  },
  sectionHeader: {
    fontSize: "28px",
    fontWeight: "700",
  },
  card: {
    width: { xs: "375px", md: "560px" },
    boxShadow: "2px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: "16px",
    },
  },
  cardBoxContent: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "700",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  employeeInfoWrapper: {
    display: "flex",
    marginBottom: { xs: 1, md: "12px" },
  },
  employeeInfoContent: {
    marginLeft: "10px",
  },
  employeeName: {
    fontSize: { xs: "18px", md: "20px" },
    fontWeight: "700",
  },
  employeeHeadline: {
    fontSize: { xs: "16px", md: "18px" },
    fontWeight: "700",
    lineHeight: "26px",
    marginBottom: "8px",
  },
  employeeContent: {
    fontSize: { xs: "16px", md: "18px" },
    fontWeight: "500",
    lineHeight: "26px",
    margin: 0,
  },
  videoLinkWrapper: {
    display: "flex",
    fontSize: "15px",
    fontWeight: 700,
    color: "#2276D0",
  },
  videoLinkContent: {
    marginLeft: "8px",
    lineHeight: "24px",
  },
  additionalInformationWrapper: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    marginTop: { xs: "8px", md: "20px" },
    paddingTop: { xs: "8px", md: "16px" },
  },
  additionalInformationExtensionButton: {
    textAlign: "center",
    cursor: "pointer",
  },
  icon: {
    fontSize: "18px",
  },
  iconExpansion: {
    fontSize: "24px",
    position: "relative",
    top: "4px",
  },
  accordion: {
    border: 0,
    boxShadow: "none",
    display: "flex",
    flexFlow: "column",
  },
  accordionSummary: {
    order: 1,
    minHeight: { xs: "24px", md: "48px" },
    "& > div": {
      display: "block",
      textAlign: "center",
      padding: 0,
    },
  },
  accordionDetails: {
    order: 0,
    padding: { xs: "0 0 16px 0", md: "8px 16px 16px" },
  },
  bookAppointmentButton: {
    padding: "16px 12px",
    width: "auto",
    height: "auto",
    fontSize: "15px",
    lineHeight: "22px",
  },
};

const HeaderSection = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const {
    experts,
    setOpenShoolSelelectionGDPRModal,
    setSelectedExpertBookingLink,
  } = useSchools();

  const [expanded, setExpanded] = React.useState<string | false>("");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleNavigateToBooking = (expertBookingLink: string) => {
    setOpenShoolSelelectionGDPRModal(true);
    setSelectedExpertBookingLink(expertBookingLink);
  };

  return (
    <Grid item xs={12} mt={2} mb={8} sx={stylesComponent.sectionWrapper}>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box sx={stylesComponent.sectionHeaderWrapper}>
          <Typography sx={stylesComponent.sectionHeader}>
            {t("EmployeeSelectHeader")}
          </Typography>
        </Box>

        {experts.map((expert: Expert, index: number) => (
          <Box mt={4} key={"expert_" + index}>
            <Card sx={stylesComponent.card}>
              <CardContent sx={stylesComponent.cardContent}>
                <Link
                  onClick={() => handleNavigateToBooking(expert.bookingsLink)}
                  underline="none"
                >
                  <Box sx={stylesComponent.cardBoxContent}>
                    <Box>
                      <Box sx={stylesComponent.employeeInfoWrapper}>
                        <img
                          src={expert.image}
                          alt="Avatar"
                          height={48}
                          width={48}
                          style={{ borderRadius: 25 }}
                        />
                        <Box sx={stylesComponent.employeeInfoContent}>
                          <Typography sx={stylesComponent.employeeName}>
                            {expert.name}
                          </Typography>
                          <Typography sx={stylesComponent.employeeContent}>
                            {expert.header}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={stylesComponent.videoLinkWrapper}>
                        <img
                          src={CameraIcon}
                          alt="camera"
                          height={24}
                          width={24}
                        />
                      </Box>
                    </Box>
                    <ArrowForwardIosIcon sx={stylesComponent.icon} />
                  </Box>
                </Link>

                <Box sx={stylesComponent.additionalInformationWrapper}>
                  <Accordion
                    disableGutters
                    sx={stylesComponent.accordion}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel-${index}-content`}
                      id={`panel-${index}-header`}
                      sx={stylesComponent.accordionSummary}
                    >
                      <Typography>
                        {t("EmployerSelectpage.MoreInformation")}
                        {expanded === `panel${index}` ? (
                          <ExpandLess sx={stylesComponent.iconExpansion} />
                        ) : (
                          <ExpandMore sx={stylesComponent.iconExpansion} />
                        )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={stylesComponent.accordionDetails}>
                      {expert.questions.map(
                        (
                          {
                            question,
                            answer,
                          }: {
                            question: string;
                            answer: string;
                          },
                          index: number
                        ) => (
                          <Box key={index}>
                            <Typography
                              mt={2}
                              sx={stylesComponent.employeeHeadline}
                            >
                              {question}
                            </Typography>
                            <Typography
                              mt={2}
                              sx={stylesComponent.employeeContent}
                            >
                              {answer}
                            </Typography>
                          </Box>
                        )
                      )}
                      <Button
                        sx={stylesComponent.bookAppointmentButton}
                        onClick={() =>
                          handleNavigateToBooking(expert.bookingsLink)
                        }
                      >
                        {t("EmployerSelectpage.BookAppointmentButton")}
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default HeaderSection;
