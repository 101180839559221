import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const stylesComponent = {
  sectionWrapper: {
    textAlign: "left",
    padding: "0 20px",
    a: {
      color: "#E60005",
      fontWeight: 500,
      fontSize: "18px",
      textDecoration: "none",
    }
  },
  sectionHeader: {
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "40px",
    mt: { xs: 4 },
    mb: 2,
  },
  pageHeader: {
    mt: 4,
    mb: 2,
  },
  pageSubHeader: {
    mt: 4,
    mb: 2,
  },
  sectionContent: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLink: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLabel: {
    fontSize: "22px",
    fontWeight: "700",
    "& span": {
      marginLeft: "5px",
    },
  },
  navigation: {
    fontSize: "15px",
    "& span": {
      color: "#2276D0",
    },
  },
  linkColor: {
    color: "#2276D0",
    fontSize: "18px",
    fontWeight: "700",
  },
  link: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    fontSize: "18px",
    marginLeft: "5px",
  },
  URLLink: {
    color: "#E60005",
    fontWeight: 500,
    textDecoration: "none",
  },
};

const AccessabilitySection = () => {
  const { t } = useTranslation("base");

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"}>
          <Box mt={{ xs: 4, md: "120px" }}>
            <Typography sx={stylesComponent.pageHeader} variant="h1">
              {t("Accessability.Title")}
            </Typography>
            <Typography sx={stylesComponent.pageSubHeader} variant="h2">
              {t("Accessability.SubTitle")}
            </Typography>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("Accessability.Content.Sections.0.Headline")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("Accessability.Content.Sections.0.Content")}
            </Typography>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("Accessability.Content.Sections.1.Headline")}
            </Typography>
            <Typography mt={1} sx={stylesComponent.sectionContent}>
              {t("Accessability.Content.Sections.1.Content")}
            </Typography>
            <Typography sx={stylesComponent.sectionHeader}>
              {t("Accessability.Content.Feedback.Headline")}
            </Typography>
            <Trans i18nKey="Accessability.Content.Feedback">
              <Typography mt={1} sx={stylesComponent.sectionContent}>
                Etwaige Mängel in Bezug auf die Einhaltung der Barrierefreiheitsanforderungen können Sie uns über unser <Link to="/kontakt">Kontakt-Formular</Link> mitteilen.<br /><br />
                Zuständig für die barrierefreie Zugänglichkeit ist:<br />
                Deutsches Rotes Kreuz e.V.<br />
                Carstennstraße  58<br />
                10249 Berlin<br />
                E-Mail: realtalk@drk.de<br />
              </Typography>
            </Trans>
          </Box>
          <Box mb={{ xs: 4, md: "120px" }}></Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default AccessabilitySection;
