import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../assets/checkIcon.svg";

const stylesComponent = {
  sectionWrapper: {
    textAlign: "left",
    margin: "0 20px",
  },
};

const ContentSection = (): React.ReactElement => {
  const { t } = useTranslation("base");

  const contentSectionObject = t("BecomeExpertContentSection.ContentList", {
    returnObjects: true,
  });
  const contentSectionList = Object.values(contentSectionObject);

  return (
    <Grid item xs={12} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={{ xs: "600px", md: "677px" }}>
          <Box mt={{ xs: 4, md: "40px" }}>
            <Typography mb={1} variant="h2">
              {t("BecomeExpertContentSection.Header")}
            </Typography>
            <Typography>{t("BecomeExpertContentSection.Content")}</Typography>

            <Box mt={2}>
              {contentSectionList.map((listItem, itemIndex) => (
                <Box key={itemIndex} mb={2} display={"flex"}>
                  <Box mr={2}>
                    <img src={CheckIcon} alt="DRK" height={40} width={40} />
                  </Box>
                  <Box>
                    <b style={{ marginRight: "5px" }}>{listItem[0]}</b>
                    <span>{listItem[1]}</span>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContentSection;
