import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type Props = {
  locationObjectName: string;
};

const stylesComponent = {
  sectionWrapper: {
    backgroundColor: "#EBF5FF",
  },
  sectionContent: {
    fontSize: "18px",
    fontWeight: "500",
    padding: "0 30px",
  },
  navigation: {
    fontSize: "15px",
    "& a": {
      color: "#2276D0",
    },
  },
};

const Navigation = ({ locationObjectName }: Props): React.ReactElement => {
  const { t } = useTranslation("base");
  let location = useLocation();

  return (
    <Grid item xs={12} pt={6} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"800px"}>
          <Box mb={4} sx={stylesComponent.navigation}>
            <Link underline="none" href={`/`} rel="noopener noreferrer">
              Home
            </Link>{" "}
            /{" "}
            {(location.pathname === "/peer-werden" ||
              location.pathname === "/experte-werden") && (
              <>
                <Link
                  underline="none"
                  href={`/mitmachen`}
                  rel="noopener noreferrer"
                >
                  {t("ParticipateSectionHeader.0")}
                </Link>

                <span style={{ color: "#333" }}> / </span>
              </>
            )}
            {location.pathname === "/experte-werden"
              ? t("ParticipateSectionHeader.2")
              : location.pathname === "/peer-werden"
              ? t("ParticipateSectionHeader.3")
              : t(`${locationObjectName}.0`)}
          </Box>
          <Typography mb={3} variant="h1" pl={2} pr={2}>
            {t(`${locationObjectName}.0`)}
          </Typography>
          <Typography mb={6} sx={stylesComponent.sectionContent}>
            {t(`${locationObjectName}.1`)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Navigation;
