import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const stylesComponent = {
  sectionWrapper: {
    textAlign: "left",
    margin: "0 20px",
    "& a": {
      color: "#E60005",
    },
  },
  sectionHeader: {
    fontWeight: "700",
    textAlign: "left",
  },
};

const ContentSection = (): React.ReactElement => {
  const { t } = useTranslation("base");

  return (
    <Grid item xs={12} pt={6} sx={stylesComponent.sectionWrapper}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={"600px"}>
          <Box mb={5}>
            <Typography sx={stylesComponent.sectionHeader} variant="h2">
              {t("AboutUs.0.Headline")}
            </Typography>
            <Typography>
              {t("AboutUs.0.Content")}
            </Typography>
          </Box>
          <Box mb={10}>
            <Typography sx={stylesComponent.sectionHeader} variant="h2">
              {t("AboutUs.1.Headline")}
            </Typography>
            <Trans i18nKey="AboutUs.1.Content">
              <Typography>
                Das Deutsche Rote Kreuz rettet Menschen, hilft in Notlagen, bietet Menschen eine Gemeinschaft, steht den Armen und Bedürftigen bei und wacht über das humanitäre Völkerrecht - in Deutschland und weltweit. Das DRK engagiert sich in verschiedenen Bereichen wie der Gesundheitsversorgung, dem Katastrophenschutz und der sozialen Unterstützung, einschließlich der Sozialarbeit an Schulen oder Jugendhilfeeinrichtungen. Du willst mehr über uns Erfahren? Hier erfährst du mehr: <Link href="https://www.drk.de/das-drk/" underline="none" target="_blank">https://www.drk.de/das-drk/</Link>
              </Typography>
            </Trans>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContentSection;
