import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Expert } from "../../../models/expert";

type Props = {
  expert: Expert;
};

const stylesComponent = {
  card: {
    height: "310px",
    maxWidth: "315px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  employeeInfoWrapper: {
    display: "flex",
  },
  employeeInfoContent: {
    marginLeft: "10px",
  },
  employeeName: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "24px",
    mb: "8px",
    mt: "18px",
  },
  employeeContent: {
    fontSize: "15px",
    fontWeight: "500",
  },
  employeeDetail: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "23px",
    mt: "16px",
  },
};

const ExpertSliderCard = ({ expert }: Props): React.ReactElement => {
  console.log(expert);

  return (
    <Card sx={stylesComponent.card}>
      <CardContent>
        <Box sx={stylesComponent.cardContent}>
          <img
            src={expert.image}
            alt="Avatar"
            height={104}
            width={104}
            style={{ borderRadius: 50 }}
          />
          <Typography sx={stylesComponent.employeeName}>
            {expert.name}
          </Typography>
          <Typography sx={stylesComponent.employeeContent}>
            {expert.quote}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExpertSliderCard;
