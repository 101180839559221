import React from "react";
import { Box, Grid } from "@mui/material";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import HeaderSection from "./HeaderSection";
import ShoolSelelectionGDPRModal from "../appointmentBooking/SchoolSelectionGDPRModal";

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
  },
};

const EmployeePage = (): React.ReactElement => {
  return (
    <Box>
      <Grid
        container
        justifyContent="space-around"
        textAlign="center"
        sx={stylesComponent.wrapper}
      >
        <Header />
        <HeaderSection />
        <Footer />
        <ShoolSelelectionGDPRModal />
      </Grid>
    </Box>
  );
};

export default EmployeePage;
