import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSchools } from "../../context/SchoolContext";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import { Checkbox } from "@mui/material";

const stylesComponent = {
  sectionWrapper: {},
  modalContentWrapper: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "560px",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    background: "none",
    color: "#000000",
    height: "14px",
    "&:hover": {
      background: "none",
    },
  },
};

const ChatAdviceGDPRModal = () => {
  const { t } = useTranslation("base");
  const {
    setOpenChatAdviceGDPRModal,
    chatAdviceOpenGDPRModal,
    chatAdviceGDPRModalWhatsAppSelected,
  } = useSchools();

  const [showValidationMsg, setShowValoidationMsg] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    setShowValoidationMsg(false);
  };

  const navigateToLink = (): void => {
    if (isChecked && chatAdviceGDPRModalWhatsAppSelected) {
      window.scrollTo(0, 0);
      window.location.href = "https://wa.me/491631438620";
      setShowValoidationMsg(false);
    }

    if (isChecked && !chatAdviceGDPRModalWhatsAppSelected) {
      window.location.href = "sms:+491631438620";
    }
    if (!isChecked) {
      setShowValoidationMsg(true);
    }
  };

  return (
    <div>
      <Modal
        sx={stylesComponent.sectionWrapper}
        open={chatAdviceOpenGDPRModal}
        onClose={() => setOpenChatAdviceGDPRModal(false)}
      >
        <Box sx={stylesComponent.modalContentWrapper}>
          <Box sx={stylesComponent.mainContentWrapper}>
            <Typography variant="h1" textAlign={"center"}>
              {t("ChatAdviceGDPRModalTitle")}
            </Typography>
            <Typography sx={{ mt: 2, textAlign: "center", fontWeight: 400 }}>
              <Trans
                i18nKey={t("ChatAdviceGDPRModalConditionText")}
                values={{
                  link1: " Nutzungsbedingungen",
                  link2: "Datenschutzbestimmungen",
                }}
                components={{
                  1: (
                    <Link
                      style={{ color: " #2276D0" }}
                      to={"/nutzungsbedingungen"}
                    />
                  ),
                  2: <Link style={{ color: " #2276D0" }} to={"/datenschutz"} />,
                }}
              />
            </Typography>

            <Box
              display={"flex"}
              flexDirection={"row"}
              mt={2}
              alignItems={"start"}
            >
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={isChecked}
                onChange={handleCheck}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography sx={{ mt: 1, textAlign: "start", fontWeight: 400 }}>
                {t("ChatAdviceGDPRModalAgreemnetText")}
              </Typography>
            </Box>
            {showValidationMsg && (
              <Box display={"flex"} alignItems={"start"} width={"100%"} mt={2}>
                <Typography
                  sx={{
                    textAlign: "start",
                    fontWeight: 400,

                    color: "red",
                  }}
                >
                  {t("ChatAdviceGDPRModalValidationMsg")}
                </Typography>
              </Box>
            )}
            <Button sx={{ mt: 6 }} onClick={() => navigateToLink()}>
              {chatAdviceGDPRModalWhatsAppSelected
                ? t("ChatAdviceGDPRModalWhatsappButtonText")
                : t("ChatAdviceGDPRModalSMSButtonText")}
            </Button>

            <Button
              sx={stylesComponent.cancelButton}
              onClick={() => setOpenChatAdviceGDPRModal(false)}
            >
              {t("ShoolSelelectionGDPRModalChancelButtonText")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChatAdviceGDPRModal;
