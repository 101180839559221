import React from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import ContentSection from "./ContentSection";
import Navigation from "../shared/Navigation";
import ContactUs from "./ContactUs";
import Participate from "../../assets/participate.jpeg";

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
  },
};

const BecameExpert = (): React.ReactElement => {
  const isMobile = useMediaQuery("(max-width:700px)", { noSsr: true });

  return (
    <Box>
      <Grid
        container
        justifyContent="space-around"
        textAlign="center"
        sx={stylesComponent.wrapper}
      >
        <Header />
        <Navigation locationObjectName={"BecameExpertSectionHeader"} />
        <Grid container display={"flex"} justifyContent={"center"} mb={"40px"}>
          <Box mt={{ xs: 6 }} mb={{ xs: 0, md: "24px" }} mr={isMobile ? 0 : 5}>
            {isMobile ? (
              <img
                src={Participate}
                alt="Peers"
                height={200}
                width={355}
                style={{ objectFit: "cover" }}
              />
            ) : (
              <img
                src={Participate}
                alt="Peers"
                height={363}
                width={568}
                style={{ objectFit: "cover" }}
              />
            )}
          </Box>
          <Box>
            <ContentSection />
            <ContactUs />
          </Box>
        </Grid>
        <Footer />
      </Grid>
    </Box>
  );
};

export default BecameExpert;
