import React from "react";
import { ChevronRight } from "@mui/icons-material";

const stylesComponent = {
    btnIcon: {
        color: "#E60005",
        fontSize: "24px",
        position: "relative",
        top: "6px",
    },
};

const LinkChevronIcon = (): React.ReactElement => {
    return (
        <ChevronRight sx={stylesComponent.btnIcon} />
    );
};

export default LinkChevronIcon;
