import { Box, Grid } from "@mui/material";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import AccessabilitySection from "./AccessabilitySection";

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
  },
};

const AccessabilityPage = (): React.ReactElement => {
  return (
    <Box>
      <Grid
        container
        justifyContent="space-around"
        textAlign="center"
        sx={stylesComponent.wrapper}
      >
        <Header />
        <AccessabilitySection />
        <Footer />
      </Grid>
    </Box>
  );
};

export default AccessabilityPage;
