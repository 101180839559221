import React from "react";
import {
  Box,
  Grid,
  Drawer,
  useMediaQuery,
  Link,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LogoIcon from "../../assets/Logo_Final.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DrawerMenu from "./DrawerMenu";
import { useTranslation } from "react-i18next";

const stylesComponent = {
  contentWrapper: {
    position: "sticky",
    zIndex: "998",
    top: "0",
    overflow: "inherit",
    width: "100%",
    backgroundColor: "#fff",
    borderBottom: "1px solid #D9D9D9",
  },
  linksBox: {
    display: "flex",
    alignItems: "center",
    justiyContent: "center",
    textAlign: "center",
    margin: { md: "auto" },
    p: 2,
  },
  drawerMenu: {
    "& .MuiDrawer-paper": {
      maxWidth: "100%",
      overflowX: "hidden",
    },
  },
  menuItem: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "26px",
    marginRight: { xs: "25px", md: "40px" },
    cursor: "pointer",
    "& .MuiTypography-root": {
      color: "#000",
    },
  },
  emptyBox: {
    width: "24px",
  },
  menuIcon: {
    width: "24px",
    height: "24px",
    marginRight: "24px",
    cursor: "pointer",
  },
  backIcon: {
    cursor: "pointer",
  },
  backIconBox: {
    width: "24px",
    height: "24px",
    paddingTop: "2px",
    marginLeft: "24px",
  },
};

const Header = (): React.ReactElement => {
  let location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("base");
  const [isDrawerMenuOpen, setIsDrawerMenuOpen] =
    React.useState<boolean>(false);
  const toggleDrawer = (open: boolean) => {
    setIsDrawerMenuOpen(open);
  };

  const menuList = () => (
    <DrawerMenu setIsDrawerMenuOpen={setIsDrawerMenuOpen} />
  );

  const navigateToLink = (link: string): void => {
    window.scrollTo(0, 0);
    navigate(link);
  };

  const isMobile = useMediaQuery("(max-width:700px)", { noSsr: true });

  const menuItemsObject = t("DesktopMenu", { returnObjects: true });
  const navigationLinksList = [
    "chat-beratung",
    "terminbuchung",
    "faq",
    "ueber-uns",
    "mitmachen",
  ];

  if (isMobile) {
    return (
      <Grid
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={1}
      >
        <Box sx={stylesComponent.backIconBox}>
          {location.pathname !== "/" ? (
            <ArrowBackIosIcon
              sx={stylesComponent.backIcon}
              onClick={() => navigateToLink("/")}
            />
          ) : (
            <Box sx={stylesComponent.emptyBox}>&nbsp;</Box>
          )}
        </Box>

        <Link underline="none" href="/">
          <img
            src={LogoIcon}
            alt="Realtalk"
            onClick={() => navigateToLink("/")}
          />
        </Link>
        <MenuIcon
          sx={stylesComponent.menuIcon}
          onClick={() => toggleDrawer(!isDrawerMenuOpen)}
        />

        <Drawer
          anchor="right"
          open={isDrawerMenuOpen}
          variant="temporary"
          sx={stylesComponent.drawerMenu}
          onClose={() => setIsDrawerMenuOpen(false)}
        >
          {menuList()}
        </Drawer>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid
          item
          lg={3}
          md={12}
          xl={6}
          sm={12}
          p={2}
          pl={{ xl: 6 }}
          display={"flex"}
          justifyContent={{ sm: "center" }}
        >
          <Link underline="none" href="/" pl={2}>
            <img
              src={LogoIcon}
              alt="Realtalk"
              width={"160px"}
              onClick={() => navigateToLink("/")}
            />
          </Link>
        </Grid>
        <Grid
          item
          md={12}
          lg={9}
          xl={6}
          sm={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={{ lg: "flex-end", sm: "center" }}
          pb={{ lg: 0, sm: 2 }}
        >
          <Box textAlign="left" ml={4} display={"flex"} flexDirection={"row"}>
            {Object.values(menuItemsObject).map((menuItem, itemIndex) => (
              <Typography key={itemIndex} sx={stylesComponent.menuItem}>
                <Link
                  underline="none"
                  href={`/${navigationLinksList[itemIndex]}`}
                  rel="noopener noreferrer"
                >
                  {menuItem}
                </Link>
              </Typography>
            ))}
          </Box>
        </Grid>
      </>
    );
  }
};

export default Header;
