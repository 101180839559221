import React, { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import HeaderSection from "./HeaderSection";
import PeersInfoSection from "./PeersInfoSection";
import JoinPeersSection from "./JoinPeersSection";
import QuestionsSection from "../shared/QuestionsSection";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import StartInfoLabel from "./StartInfoLabel";
import openingHoursService from "../../services/openinigHoursService";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OpeningHours, OpeningHoursState } from "../../models/openingHours";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment from "moment-timezone";
import ChatAdviceGDPRModal from "./ChatAdviceGDPRModal";
import TopicsSection from "./TopicsSection";
import InfoSection from "./InfoSection";

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
  },
};

const ChatAdvice = (): React.ReactElement => {
  // will be used once is chat open

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openingHoursData, setOpeningHoursData] = useState<OpeningHours>();
  const [currentTimeState, setCurrentTimeState] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isChatOpen = useRef(false);

  const fetchOpeningHours = () => {
    openingHoursService
      .getOpeningHoursService()
      .then((res: any) => {
        setOpeningHoursData(res.data.data.openingHours);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchOpeningHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // uncomment this when activate chat !!!!!

  useEffect(() => {
    if (openingHoursData) {
      isChatOpen.current = isWithinOpeningHours(openingHoursData);
    }
  }, [openingHoursData]);

  const isWithinOpeningHours = (openingHours: OpeningHours) => {
    const frankfurtTime = moment.tz("Europe/Berlin");

    const dayOfWeek = frankfurtTime.format("dddd");
    const currentDay = openingHours[dayOfWeek];
    setCurrentTimeState(currentDay);

    if (currentDay && currentDay.state === OpeningHoursState.CLOSED) {
      return false;
    }

    const currentTime = frankfurtTime.format("HH:mm");

    const openTime = currentDay.open;
    const closeTime = currentDay.close;

    return currentTime >= openTime && currentTime <= closeTime;
  };

  return (
    <Box>
      <Grid
        container
        justifyContent="space-around"
        textAlign="center"
        sx={stylesComponent.wrapper}
      >
        <Header />
        {/* {!isChatOpen.current && <StartInfoLabel />} */}
        <HeaderSection
          isChatOpen={isChatOpen.current}
          currentTime={currentTimeState}
          openingHours={openingHoursData}
        />
        <InfoSection />
        <TopicsSection />
        <PeersInfoSection />
        <Box mt={{ xs: "48px" }} pt={10} bgcolor={"#FBFBFB"} width={"100%"}>
          <QuestionsSection
            questionsType={""}
            questionsObjectName={"FAQQuestions.ChatAdvice"}
          />
        </Box>

        <JoinPeersSection />
        <Footer />
        <ChatAdviceGDPRModal />
      </Grid>
    </Box>
  );
};

export default ChatAdvice;
