import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Peer } from "../../../models/peer";

type Props = {
  peer: Peer;
};

const stylesComponent = {
  card: {
    maxWidth: "316px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  employeeName: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32x",
    mt: "24px",
  },
  employeeContent: {
    fontSize: "18px",
    fontWeight: "500",
    fontStyle: "italic",
    textAlign: "center",
    p: "12px",
  },
};

const PeerSliderCard = ({ peer }: Props): React.ReactElement => {
  return (
    <Card sx={stylesComponent.card}>
      <CardContent>
        <Box sx={stylesComponent.cardContent}>
          <img
            src={peer.avatar}
            alt="Avatar"
            height={130}
            width={120}
            style={{ borderRadius: 100, background: "#EBF5FF" }}
          />
          <Typography sx={stylesComponent.employeeName}>{peer.name}</Typography>
          <Typography sx={stylesComponent.employeeContent}>
            {peer.description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PeerSliderCard;
