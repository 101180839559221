import React from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandIcon from "../../assets/expandIcon.svg";
import WrappedIcon from "../../assets/wrappedIcon.svg";
import LinkChevronIcon from "./LinkChevronIcon";

type Props = {
  questionsType: string;
  questionsObjectName: string;
};

const stylesComponent = {
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #B4B4B4",
    animation: "fadeIn 1s",
    "&::before": {
      display: "none",
    },
    backgroundColor: "transparent",
    margin: { xs: "0 19px" },
    padding: { xs: "16px 0", md: "24px 0" },
  },
  question: {
    fontWeight: "700",
    lineHeight: "23px",
    boxShadow: "none",
  },
  moreQuestions: {
    textAlign: "center",
    "& a": {
      fontWeight: "700",
      color: "#E60005",
      fontSize: "18px",
    },
  },
  icon: {
    color: "#002D55",
  },
};

const QuestionsSection = ({
  questionsType,
  questionsObjectName,
}: Props): React.ReactElement => {
  const { t } = useTranslation("base");
  const questionsObject = t(questionsObjectName, { returnObjects: true });
  const [expanded, setExpanded] = React.useState<string | false>("");

  const getAccordion = (
    question: string,
    answer: string,
    questionIndex: number
  ) => {
    const handleChange =
      (panel: string) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };

    return (
      <Accordion
        square
        disableGutters
        onChange={handleChange(`panel${questionIndex}`)}
        key={questionIndex + 1}
        sx={stylesComponent.accordion}
        expanded={expanded === `panel${questionIndex}`}
      >
        <AccordionSummary
          expandIcon={
            expanded === `panel${questionIndex}` ? (
              <img src={WrappedIcon} alt="Realtalk" />
            ) : (
              <img src={ExpandIcon} alt="Realtalk" />
            )
          }
          aria-controls={`panel-${questionIndex.toString()}-content`}
          id={`panel${questionIndex.toString()}`}
          sx={{ padding: 0, margin: 0 }}
        >
          <Typography
            textAlign="left"
            sx={stylesComponent.question}
            pr={1}
            whiteSpace={"pre-line"}
          >
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0, fontSize: "18px", margin: 0 }}>
          <Typography textAlign={"left"} whiteSpace={"pre-line"}>
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Grid item xs={12}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box maxWidth={{ xs: "600px", md: "700px" }} width="100%">
          {questionsType === "" ? (
            <Typography variant={"h2"}>{t("FAQHeader")}</Typography>
          ) : (
            <Box ml={{ xs: 2, md: 0 }}>
              <Typography variant="h2">{t(questionsType)}</Typography>
            </Box>
          )}

          <Box mt={3}>
            {Object.values(questionsObject).map((question, questionIndex) =>
              getAccordion(question.Question, question.Answer, questionIndex)
            )}
          </Box>
          {questionsType === "" && (
            <Typography mt={4} mb={8} sx={stylesComponent.moreQuestions}>
              <Link underline="none" href={`/faq`} rel="noopener noreferrer">
                {t("MoreQuestions")}
                <LinkChevronIcon />
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default QuestionsSection;
