import axios, { AxiosResponse } from "axios";

const {
  REACT_APP_BASE_SCHOOL_SELECTION_URL,
  REACT_APP_SCHOOL_SELECTION_APIKEY,
} = process.env;

class openingHoursService {
  getOpeningHoursService = async (): Promise<AxiosResponse> => {
    return axios.get(`${REACT_APP_BASE_SCHOOL_SELECTION_URL}/openingHours`, {
      headers: {
        "x-api-key": REACT_APP_SCHOOL_SELECTION_APIKEY,
      },
    });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new openingHoursService();
