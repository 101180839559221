import "./App.css";
import Router from "./router";
import SchoolContext from "./context/SchoolContext";

const App = () => (
  <SchoolContext>
    <Router />
  </SchoolContext>
);

export default App;
