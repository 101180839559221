import React from "react";
import { Box, Grid, Typography, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const stylesComponent = {
  topics: {
    mt: { xs: "64px", md: "120px 0" },
    padding: "0 10px",
  },
  headerWrapper: {
    h2: {
      marginBottom: 0,
    },
  },
  header: {
    marginBottom: 0,
  },
  chipWrapper: {
    margin: { xs: 2, md: "64px 0" },
    maxWidth: { md: "1050px" },
  },
  chip: {
    margin: { xs: "8px 4px", md: "8px" },
    padding: { xs: "7px 12px", md: "7px 20px" },
    height: { xs: "40px", md: "52px" },
    border: "2px solid",
    borderRadius: { xs: "20px", md: "26px" },
    "& .MuiChip-label": {
      fontWeight: "700",
      fontSize: "18px",
      padding: 0,
    },
  },
};

const TopicsSection = (): React.ReactElement => {
  const topicsList = [
    "💊 Alkohol & Drogen",
    "💔 Liebe & Sex ",
    "💢 Gewalt",
    "🏠 Familie",
    "😰 Ängste",
    "🧑‍🤝‍🧑 Identität",
    "😣 Stress",
    "📚 Schule",
    "💸 Ökonomie",
    "🤰 Schwangerschaft",
    "👾 Cyber-Mobbing",
    "😔 Einsamkeit",
    "🍫 Essstörungen",
    "💤 Erschöpfung",
    "🤕 Körper & Krankheit",
    "🗯️ Mobbing",
    "🤯 Überforderung",
  ];
  const { t } = useTranslation("base");
  return (
    <Grid item xs={12} sx={stylesComponent.topics}>
      <Box
        width={{ md: "100%" }}
        display={{ md: "flex" }}
        flexDirection={{ md: "column" }}
        alignItems={{ md: "center" }}
      >
        <Box sx={stylesComponent.headerWrapper}>
          <Typography variant="h2" sx={stylesComponent.header}>
            {t("Topics")}
          </Typography>
        </Box>
        <Box sx={stylesComponent.chipWrapper}>
          {topicsList.map((_, itemIndex) => (
            <Chip
              label={topicsList[itemIndex]}
              variant="outlined"
              sx={stylesComponent.chip}
              color="info"
              key={itemIndex}
            />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default TopicsSection;
