import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

type Props = {
  i18nKey: string;
};

type ContentStructure = {
  type: 'text' | 'headline' | 'list' | 'emptyline';
  text?: string;
  list?: ContentStructure[];
  before?: ContentStructure;
  after?: ContentStructure;
}

const stylesComponent = {
  sectionWrapper: {
    textAlign: "justify",
    padding: "0 20px",
    wordBreak: 'break-word',
    a: {
      color: "#E60005",
      fontWeight: 500,
      fontSize: "18px",
      textDecoration: "none",
    }
  },
  sectionHeader: {
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "40px",
    mt: { xs: 4 },
    mb: 2,
  },
  pageHeader: {
    mt: 4,
    mb: 2,
  },
  pageSubHeader: {
    mt: 4,
    mb: 2,
  },
  sectionContent: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLink: {
    fontSize: "18px",
    fontWeight: "500",
    "& span": {
      marginLeft: "5px",
    },
  },
  sectionContentLabel: {
    fontSize: "22px",
    fontWeight: "700",
    "& span": {
      marginLeft: "5px",
    },
  },
  navigation: {
    fontSize: "15px",
    "& span": {
      color: "#2276D0",
    },
  },
  linkColor: {
    color: "#2276D0",
    fontSize: "18px",
    fontWeight: "700",
  },
  link: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    fontSize: "18px",
    marginLeft: "5px",
  },
  URLLink: {
    color: "#E60005",
    fontWeight: 500,
    textDecoration: "none",
  },
};

const makeid = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const contentAreaHeadline = (text: string): React.ReactElement => {
  return (
    <Typography sx={stylesComponent.pageSubHeader} variant="h2">
      {text}
    </Typography>
  )
}
const contentAreaText = (text: string): React.ReactElement => {
  return (
    <Typography mb={1}>
      {parse(text)}
    </Typography>
  )
}

const contentAreaEmptyLine = (): React.ReactElement => {
  return (
    <>
      <br />
    </>
  )
}

const contentAreaList = (list: ContentStructure[]): React.ReactElement => {
  return (
    <>
      <ul>
        {list.map((c) => (
          <li key={makeid(20)}>
            {contentAreaParser(c, true)}
          </li>
        ))}
      </ul>
    </>
  )
}

const contentAreaParser = (content: ContentStructure, padding: boolean = true): React.ReactElement => {
  const renderSwitch = (content: ContentStructure, padding: boolean = true): React.ReactElement => {
    switch (content.type) {
      case 'headline': return contentAreaHeadline(content.text!);
      case 'text': return contentAreaText(content.text!);
      case 'list': return contentAreaList(content.list!);
      case 'emptyline': return contentAreaEmptyLine();
    }
  }

  return (
    <>
      {(content.before && contentAreaParser(content.before))}
      {renderSwitch(content)}
      {(content.after && contentAreaParser(content.after))}
    </>
  )
}

const ContentArea = ({ i18nKey }: Props): React.ReactElement => {
  const { t } = useTranslation("base");
  const content: ContentStructure[] = t(i18nKey, { returnObjects: true })

  return (
    <>
      {content.map((c) => contentAreaParser(c))}
    </>
  )
};

export default ContentArea;
