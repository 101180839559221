import React from "react";
import { Box, Grid, Typography, Link, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Peers from "../../assets/peers2.jpg";
import Participate from "../../assets/participate2.jpg";
import LinkChevronIcon from "../shared/LinkChevronIcon";

const stylesComponent = {
  sectionHeader: {
    margin: "20px 40px 12px",
    marginBottom: { xs: "12px", md: "20px" },
  },
  sectionContent: {
    margin: "20px 32px",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
  },
  contactUsBtn: {
    "& a": {
      color: "#E60005",
      fontWeight: "700",
    },
  },
  link: {
    color: "#E60005",
    fontWeight: 500,
    fontSize: "18px",
    textDecoration: "none",
  },
};

const ChooseParticipateSection = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const isMobile = useMediaQuery("(max-width:700px)", { noSsr: true });

  return (
    <Grid
      container
      xs={12}
      mt={6}
      width={{ md: "100%" }}
      display={{ md: "flex" }}
      justifyContent="center"
    >
      <Box maxWidth={{ xs: "600px", md: "534px" }}>
        <Box>
          {isMobile ? (
            <img
              src={Peers}
              alt="Peers"
              width={355}
              style={{ borderRadius: "20px", objectFit: "cover" }}
            />
          ) : (
            <img
              src={Peers}
              alt="Peers"
              width={502}
              style={{ borderRadius: "20px", objectFit: "cover" }}
            />
          )}
        </Box>
        <Typography sx={stylesComponent.sectionContent}>
          {t("TeenagersSectionContent")}
        </Typography>
        <Box mt={3}>
          <Typography mb={6} sx={stylesComponent.contactUsBtn}>
            <Link
              underline="none"
              href={`/peer-werden`}
              rel="noopener noreferrer"
            >
              {t("ParticipateMoreQuestion")}
              <LinkChevronIcon />
            </Link>
          </Typography>
        </Box>
      </Box>

      <Box mb={6} maxWidth={{ xs: "600px", md: "534px" }}>
        <Box>
          {isMobile ? (
            <img
              src={Participate}
              alt="peers"
              width={355}
              style={{ borderRadius: "20px", objectFit: "cover" }}
            />
          ) : (
            <img
              src={Participate}
              alt="peers"
              width={502}
              style={{ borderRadius: "20px" }}
            />
          )}
        </Box>
        <Typography sx={stylesComponent.sectionContent}>
          {t("SocialWorkersContent")}
        </Typography>
        <Box mt={2}>
          <Typography mb={3} sx={stylesComponent.contactUsBtn}>
            <Link
              underline="none"
              href={`/experte-werden`}
              rel="noopener noreferrer"
            >
              {t("ParticipateMoreQuestion")}
              <LinkChevronIcon />
            </Link>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default ChooseParticipateSection;
