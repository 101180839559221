import React from "react";
import { ReactNode } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import IndexLayout from "./components/indexPage";
import ChatAdvice from "./components/chatAdvice";
import EmployeePage from "./components/employeePage";
import AppointmentBooking from "./components/appointmentBooking";
import FAQPage from "./components/FAQPage";
import AboutUs from "./components/aboutUs";
import ParticipatePage from "./components/participatePage";
import BecamePeer from "./components/becamePeer";
import Contact from "./components/Contact";
// import OtherAdvice from "./components/otherAdvice"; TODO: AndereBeratungen
import ImpressumPage from "./components/impressumPage";
import DataProtectionPage from "./components/dataProtectionPage";
import AccessabilityPage from "./components/AccessabilityPage";
import BecameExpert from "./components/becameExpert";
import TermsOfUsePage from "./components/termsOfUse";

const Router = (): React.ReactElement => {
  const getRoutingPages = (): ReactNode => {
    return (
      <Routes>
        <Route path="/" element={<IndexLayout />} />
        <Route path="/chat-beratung" element={<ChatAdvice />} />
        <Route path="/impressum" element={<ImpressumPage />} />
        <Route path="/datenschutz" element={<DataProtectionPage />} />
        <Route path="/barrierefreiheit" element={<AccessabilityPage />} />
        <Route path="/terminbuchung" element={<AppointmentBooking />} />
        <Route path="/expertenauswahl" element={<EmployeePage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/ueber-uns" element={<AboutUs />} />
        <Route path="/mitmachen" element={<ParticipatePage />} />
        <Route path="/peer-werden" element={<BecamePeer />} />
        <Route path="/experte-werden" element={<BecameExpert />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/nutzungsbedingungen" element={<TermsOfUsePage />} />
        {/* <Route path="/andere-beratungsangebote" element={<OtherAdvice />} /> TODO: AndereBeratungen */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  };

  return <BrowserRouter>{getRoutingPages()}</BrowserRouter>;
};

export default Router;
