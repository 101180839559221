import axios, { AxiosResponse } from "axios";
import { ContactFormData } from "../models/contactFormData";

const {
  REACT_APP_BASE_SCHOOL_SELECTION_URL,
  REACT_APP_SCHOOL_SELECTION_APIKEY,
} = process.env;

class contactService {
  sendContactForm = async (
    contactFormData: ContactFormData
  ): Promise<AxiosResponse> => {
    const body = contactFormData;
    return axios.post(
      `${REACT_APP_BASE_SCHOOL_SELECTION_URL}/sendEmail`,
      body,
      {
        headers: {
          "x-api-key": REACT_APP_SCHOOL_SELECTION_APIKEY,
        },
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new contactService();
