import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AnonymIcon from "../../assets/anonymousIcon.svg";
import HeartIcon from "../../assets/heartIcon.svg";
import StarIcon from "../../assets/starIcon.svg";

const stylesComponent = {
  itemsWrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "center",
    alignItems: { xs: "center", lg: "baseline" },
    p: "48px 40px 48px 40px",
  },
  boxWrapper: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    alignItems: { xs: "center", lg: "flex-start" },
    justifyContent: "center",
    mr: { xs: 0, md: "32px" },
  },
  textsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", lg: "flex-start" },
    justifyContent: "center",
    width: { xs: "auto", lg: "240px" },
    pl: { md: "16px" },
    mt: { xs: "16px", lg: "0" },
  },
  iconTextHeader: {
    textAlign: { xs: "center", md: "left" },
    marginBottom: 0,
    paddingBottom: 0,
  },
  iconText: {
    fontSize: "18px",
    textAlign: {
      xs: "center",
      md: "left",
    },
  },
};

const InfoSection = (): React.ReactElement => {
  const { t } = useTranslation("base");

  return (
    <Grid xs={12}>
      <Box sx={stylesComponent.itemsWrapper}>
        <Box sx={stylesComponent.boxWrapper}>
          <img src={AnonymIcon} alt="DRK" height={45} width={45} />
          <Box sx={stylesComponent.textsWrapper}>
            <Typography variant="h3" sx={stylesComponent.iconTextHeader}>
              {t("Anonym.0")}
            </Typography>
            <Typography mt={2} sx={stylesComponent.iconText}>
              {t("Anonym.1")}
            </Typography>
          </Box>
        </Box>

        <Box mt={4} sx={stylesComponent.boxWrapper}>
          <img src={HeartIcon} alt="DRK" height={45} width={45} />
          <Box sx={stylesComponent.textsWrapper}>
            <Typography variant="h3" sx={stylesComponent.iconTextHeader}>
              {t("ConfidentialConversations.0")}
            </Typography>
            <Typography mt={2} sx={stylesComponent.iconText}>
              {t("ConfidentialConversations.1")}
            </Typography>
          </Box>
        </Box>

        <Box mt={4} sx={stylesComponent.boxWrapper}>
          <img src={StarIcon} alt="DRK" height={45} width={45} />
          <Box sx={stylesComponent.textsWrapper}>
            <Typography variant="h3" sx={stylesComponent.iconTextHeader}>
              {t("FreeOffer.0")}
            </Typography>
            <Typography mt={2} sx={stylesComponent.iconText}>
              {t("FreeOffer.1")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default InfoSection;
