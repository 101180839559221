import { createTheme } from '@mui/material/styles'
import { grey, indigo } from '@mui/material/colors'

const t = createTheme({
  palette: {
    primary: {
      light: grey[100],
      dark: '#333333',
      contrastText: '#fff',
      main: grey[400],
    },
    secondary: {
      main: grey[500],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          width: "366px",
          height: "56px",
          borderRadius: "8px",
          marginTop: "20px",
          textTransform: "inherit",
          fontWeight: "700",
          fontSize: "18px",
          backgroundColor: "#E60005",
          "&:hover": {
            backgroundColor: "#E60005",
          },
          "&.Mui-disabled": {
            backgroundColor: "#B4B4B4",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#B4B4B4",
              cursor: "default",
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Helvetica Neue,Sans-Serif,BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif",
    fontSize: -1,
    fontWeightRegular: 500,
  }
})

t.typography.h1 = {
  fontSize: "36px",
  fontWeight: "700",
  lineHeight: "40px",
  marginBottom: "16px",
  [t.breakpoints.up("md")]: {
    fontSize: "48px",
    marginBottom: "32px",
    lineHeight: "56px",
  },
};

t.typography.h2 = {
  fontSize: "28px",
  fontWeight: "700",
  lineHeight: "36px",
  marginBottom: "16px",
  [t.breakpoints.up("md")]: {
    fontSize: "32px",
    marginBottom: "32px",
    lineHeight: "40px",
  },
}

t.typography.h3 = {
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "23px",
  [t.breakpoints.up("md")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
}

export const theme = t;

export const colors = {
  iconLight: grey[100],
  blueButton: indigo[500],
  drkRot: '#E60005',
}
