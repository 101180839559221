import React from "react";
import { Badge, Box, Button, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentArea from "../shared/ContentArea";
import Peers from "../../assets/becomePeer.svg";

const stylesComponent = {
  sectionHeader: {
    margin: "20px 40px 12px",
    marginBottom: { xs: "12px", md: "20px" },
  },
  sectionContent: {
    margin: "5px 40px",
  },
  contactUsBtn: {
    "& a": {
      color: "#E60005",
      fontWeight: "700",
    },
  },
  link: {
    color: "#E60005",
    fontWeight: 500,
    fontSize: "18px",
    textDecoration: "none",
  },
  contactBtn: {
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "700",
    borderRadius: "64px",
    float: "left",
    margin: "20px",
  },
  imageBadge: {
    "& span": {
      backgroundColor: "#E60005",
      borderRadius: "50%",
      fontWeight: "700",
      height: "199px",
      width: "199px",
      fontSize: "20px",
      lineHeight: "24px",
      marginRight: "67px",
    },
  },
  imageBadgeMobile: {
    "& span": {
      height: "144px",
      width: "144px",
      fontSize: "16px",
      lineHeight: "20px",
      marginRight: "48px",
    },
  },
};

const ContentSection = (): React.ReactElement => {
  const { t } = useTranslation("base");
  const isMobile = useMediaQuery("(max-width:700px)", { noSsr: true });

  return (
    <Grid item xs={12}>
      {isMobile && (
        <Badge
          badgeContent={t("BecomePeerPage.BadgeText")}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circular"
          sx={[stylesComponent.imageBadge, stylesComponent.imageBadgeMobile]}
        >
          <img
            src={Peers}
            alt="Peers"
            height={200}
            width={355}
            style={{ objectFit: "cover" }}
          />
        </Badge>
      )}
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
        gap={{ md: 8 }}
        mt={{ md: "120px" }}
        mb={{ md: "24px" }}
      >
        <Box maxWidth={"568px"} textAlign={"left"} m={{ xs: 2 }}>
          <ContentArea i18nKey="BecomePeerPage.Content" />
          <Button
            sx={stylesComponent.contactBtn}
            style={{ width: `${isMobile ? "calc(100% - 40px)" : "292px"}` }}
            href={"https://forms.office.com/e/z8y2Ym8Zk7"}
          >
            {t("BecomePeerPage.FormLinkText")}
          </Button>
        </Box>
        <Box>
          {!isMobile && (
            <Badge
              badgeContent={t("BecomePeerPage.BadgeText")}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
              sx={stylesComponent.imageBadge}
            >
              <img
                src={Peers}
                alt="Peers"
                height={398}
                width={568}
                style={{ objectFit: "cover" }}
              />
            </Badge>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ContentSection;
