import React from "react";
import { Box, Grid } from "@mui/material";
import IntroductionSection from "./IntroductionSection";
import InfoSection from "./InfoSection";
import TopicsSection from "./TopicsSection";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import QuestionsSection from "../shared/QuestionsSection";

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
    justifyContent: "space-around",
    textAlign: "center",
    h2: {
      textAlign: "center",
    },
  },
  boxWrapper: {
    backgroundColor: "#FBFBFB",
    mt: { xs: 8, md: "120px" },
    pt: { xs: 8, md: "120px" },
    width: "100%",
  },
  boxStyle: {
    mb: 4,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  }
};

const IndexLayout = (): React.ReactElement => {
  return (
    <Grid
      sx={stylesComponent.wrapper}
      container
    >
      <Header />
      <IntroductionSection />
      <InfoSection />
      <TopicsSection />
      <Box sx={stylesComponent.boxWrapper}>
        <Box sx={stylesComponent.boxStyle}>
          <QuestionsSection
            questionsType={""}
            questionsObjectName={"FAQQuestions.HomePage"}
          />
        </Box>
      </Box>
      <Footer />
    </Grid>
  );
};

export default IndexLayout;
