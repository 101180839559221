import i18n from "i18next";

import { initReactI18next } from "react-i18next";
import deBase from "./locales/de/base";

const langDe = {
  base: deBase,
};

const resources = {
  de: langDe,
};

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "de",
  lng: "de",
  debug: false,
  keySeparator: ".",
  interpolation: { escapeValue: false },
  initImmediate: false,
  returnNull: false,
});

export default i18n;
