import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoIcon from "../../assets/Logo_Final.svg";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  setIsDrawerMenuOpen: (isOpen: boolean) => void;
};

const stylesComponent = {
  componentWrapper: {
    zIndex: "999",
  },
  drawerHeader: {
    width: "400px",
    maxWidth: "100%",
    textAlign: "center",
    display: "flex",
    height: "40px",
    padding: "10px 0",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #D9D9D9",
    marginBottom: "60px",
  },
  emptyBox: {
    width: "24px",
    marginRight: "3.4vw",
  },
  menuWrapper: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "650px",
    "& a": {
      color: "#000",
    },
  },
  menuItem: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "50px",
    borderBottom: "1px solid #D9D9D9",
    marginRight: "25px",
    cursor: "pointer",
  },
  subMenuItem: {
    fontWeight: "500",
    fontSize: "15px",
    cursor: "pointer",
  },
  icon: {
    width: "24px",
    height: "24px",
    marginRight: "3.4vw",
    cursor: "pointer",
  },
};

const DrawerMenu = ({ setIsDrawerMenuOpen }: Props): React.ReactElement => {
  const { t } = useTranslation("base");
  const menuItemsObject = t("Menu", { returnObjects: true });
  const subMenuItemsObject = t("SubMenu", { returnObjects: true });
  const navigationLinksList = [
    "chat-beratung",
    "terminbuchung",
    "ueber-uns",
    "faq",
    "mitmachen",
    // "andere-beratungsangebote", TODO: AndereBeratungen
    "kontakt",
  ];

  const secondaryNavigationLinkList = [
    "nutzungsbedingungen",
    "datenschutz",
    "barrierefreiheit",
    "impressum",
  ];

  return (
    <Grid item xs={12} sx={stylesComponent.componentWrapper}>
      <Box sx={stylesComponent.drawerHeader}>
        <Box sx={stylesComponent.emptyBox}>&nbsp;</Box>
        <img src={LogoIcon} alt="Realtalk" />
        <CloseIcon
          sx={stylesComponent.icon}
          onClick={() => setIsDrawerMenuOpen(false)}
        />
      </Box>
      <Box sx={stylesComponent.menuWrapper}>
        <Box textAlign="left" ml={4}>
          {Object.values(menuItemsObject).map((menuItem, itemIndex) => (
            <Link
              underline="none"
              href={`/${navigationLinksList[itemIndex]}`}
              rel="noopener noreferrer"
            >
              <Typography sx={stylesComponent.menuItem} key={itemIndex}>
                {menuItem}
              </Typography>
            </Link>
          ))}
        </Box>

        <Box textAlign="left" mt={6} ml={4}>
          {Object.values(subMenuItemsObject).map((menuItem, itemIndex) => (
            <Typography sx={stylesComponent.subMenuItem} mb={2} key={itemIndex}>
              <Link
                underline="none"
                href={`/${secondaryNavigationLinkList[itemIndex]}`}
                rel="noopener noreferrer"
              >
                {menuItem}
              </Link>
            </Typography>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default DrawerMenu;
