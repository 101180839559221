import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../assets/checkIcon.svg";
import parse from "html-react-parser";

const PartnerSection = (): React.ReactElement => {
  const { t } = useTranslation("base");

  const contentSectionObject = t(
    "BecomePeerPartnerContentSection.ContentList",
    {
      returnObjects: true,
    }
  );
  const contentSectionList = Object.values(contentSectionObject);

  return (
    <Grid
      item
      xs={12}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      mb={6}
    >
      <Grid
        bgcolor={"#EBF5FF"}
        maxWidth={"1200px"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"center"}
        alignItems={"top"}
        textAlign={"start"}
        gap={8}
        p={4}
        borderRadius={{ md: 6 }}
        mt={{ md: "120px" }}
        mb={{ md: "24px" }}
      >
        <Box maxWidth={"600px"}>
          <Typography fontSize={{ md: "40px", xs: "24px" }} fontWeight={700}>
            {t("BecomePeerPartnerContentSection.Header")}
          </Typography>
        </Box>

        <Box
          maxWidth={"520px"}
          pl={{ ml: 4 }}
          display={"flex"}
          flexDirection={"column"}
        >
          {contentSectionList.map((listItem, itemIndex) => (
            <Box key={itemIndex} mb={2} display={"flex"}>
              <Box mr={2}>
                <img src={CheckIcon} alt="DRK" height={40} width={40} />
              </Box>
              <Box>{parse(listItem)}</Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PartnerSection;
