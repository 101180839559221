import React from "react";
import { Box, Grid } from "@mui/material";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import QuestionsSection from "../shared/QuestionsSection";
// eslint-disable-next-line
import MoreQuestionsSection from "./MoreQuestionsSection";
import Navigation from "../shared/Navigation";

const stylesComponent = {
  wrapper: {
    margin: "0 auto",
  },
};

const FAQPage = (): React.ReactElement => {
  return (
    <Box>
      <Grid
        container
        justifyContent="space-around"
        textAlign="center"
        sx={stylesComponent.wrapper}
      >
        <Header />
        <Navigation locationObjectName={"FAQPageHeader"} />
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <Box maxWidth={"680px"}>
            <Box mt={{ xs: 6, md: "120px" }}>
              <QuestionsSection
                questionsType={"ChatAdviseSection"}
                questionsObjectName={"ChatAdviseQuestions"}
              />
            </Box>
            <Box mt={{ xs: 5, md: "120px" }} mb={{ xs: 6, md: "160px" }}>
              <QuestionsSection
                questionsType={"AppointmentAdviseSection"}
                questionsObjectName={"AppointmentAdviseQuestions"}
              />
            </Box>
          </Box>
        </Box>
        {/* <MoreQuestionsSection /> */}
        <Footer />
      </Grid>
    </Box>
  );
};

export default FAQPage;
