import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./slickStyles.css";
import { Box } from "@mui/system";
import PeerSliderCard from "./PeerSliderCard";
import { Peer } from "../../../models/peer";

type Props = {
  peers?: Peer[];
};

export const PeerSliderSection = ({ peers }: Props): React.ReactElement => {
  const slidesToShowLengthDesktop =
    peers && peers?.length > 3 ? 3 : peers?.length;
  const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }: any) => {
    const { onClick } = props;

    return (
      <div {...props} className="custom-prevArrow" onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.2501 23.25L5.53006 12.53C5.46039 12.4604 5.40513 12.3778 5.36742 12.2869C5.32971 12.1959 5.3103 12.0984 5.3103 12C5.3103 11.9016 5.32971 11.8041 5.36742 11.7131C5.40513 11.6222 5.46039 11.5396 5.53006 11.47L16.2501 0.75"
            stroke="#2276D0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  const GalleryNextArrow = ({ currentSlide, slideCount, ...props }: any) => {
    const { onClick } = props;

    return (
      <div {...props} className="custom-nextArrow" onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5.5 0.75L16.22 11.47C16.2897 11.5396 16.3449 11.6222 16.3826 11.7131C16.4203 11.8041 16.4398 11.9016 16.4398 12C16.4398 12.0984 16.4203 12.1959 16.3826 12.2869C16.3449 12.3778 16.2897 12.4604 16.22 12.53L5.5 23.25"
            stroke="#2276D0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShowLengthDesktop,
    slidesToScroll: 1,
    nextArrow: <GalleryNextArrow />,
    prevArrow: <GalleryPrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "56px",
        boxShadow: "none",
        "& .MuiPaper-root": {
          boxShadow: "none",
        },
      }}
    >
      {peers && (
        <Slider {...settings}>
          {peers?.map((peer: any) => (
            <PeerSliderCard key={peer.name} peer={peer} />
          ))}
        </Slider>
      )}
    </Box>
  );
};
